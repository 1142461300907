// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#create-initialization-config-files

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"c97b3e04436a2fa4877f93f2f077c0b795761e88"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import sentryBaseConfig from './sentry.base.config';

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === 'true') {
  Sentry.init(sentryBaseConfig);
}
